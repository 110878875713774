<template>
    <h2> {{ title }} </h2>
    <div class="site-link-container" v-for="(site, idx) in siteList" :key="idx">
        <site-link
            :name="site.name"
            :link="site.link"
            :image="site.image"
            :colour="site.colour"
            :srcLink="site.srcLink"
            :closeFlag="closeFlag"
            @set-current-site="$emit('setCurrentSite', $event)"
        />
    </div>
</template>

<script>
import SiteLink from './SiteLink.vue';

export default {
    components: { SiteLink },
    emits: ['setCurrentSite'],
    props: [
        'title',
        'siteList',
        'closeFlag',
    ],
}
</script>

<style lang="scss" scoped>
h2 {
    width: 70%;
    max-width: 250px;
    margin: 0 auto 2rem;
    padding-bottom: 0.5rem;
    
    border-bottom: 3px solid currentColor;
}
</style>