import tinycolor from 'tinycolor2';
import * as THREE from 'three';

import screen from '../screen.json';
import normalFile from '../normal.png';
import envFile from '../env.jpg';

import imgMask from '../mask.png';
import imgScreen from '../screen-effect.png';
import imgLogo from '../logo.png';
import imgLoading from '../loading.jpg';

class ScreenDrawer {
    _lookBackTimeout = -1;
    
    _canvas;
    _flatCanvas;
    _canvasTexture;
    
    _screenWidth = 500;
    _screenHeight = 350;

    _imgMask = new Image();
    _imgScreen = new Image();
    _imgLogo = new Image();
    _imgLoading = new Image();
    _imgs = {};
    _grd;
    _image;
    _prevImage;

    constructor() {
        this._imgMask.src = imgMask;
        this._imgScreen.src = imgScreen;
        this._imgLogo.src = imgLogo;
        this._imgLoading.src = imgLoading;

        // Load THREE textures and other files
        this.normalMap = new THREE.TextureLoader().load(normalFile);
        this.envMap = new THREE.TextureLoader().load(envFile);
        this.envMap.mapping = THREE.EquirectangularReflectionMapping;
        this.envMap.encoding = THREE.sRGBEncoding;
        this.geometry = new THREE.BufferGeometryLoader().parse( screen );

        this.colour = 'white';
        this._image = 'default';
        this._firstFrame = requestAnimationFrame(() => {});

        let canvasComplete = this.canvasSetup(),
            threeComplete = this.threeInit();

        this.setupComplete = Promise.all([canvasComplete, threeComplete]);
    }

    async destructor() {
        await this.setupComplete;

        this._flatCanvas.remove();
    }

    async getCanvas() {
        await this.setupComplete;

        return this._canvas;
    }

    async setDisplay(displayOptions) {
        await this.setupComplete;

        const imgSet = displayOptions && displayOptions.hasOwnProperty('image'),
                colourSet = displayOptions && displayOptions.hasOwnProperty('colour');
        
        if (colourSet)
        {
            this.colour = displayOptions.colour;
            this._setGradient();
        }

        if (imgSet)
        {
            this._setImage(displayOptions.image || 'default');
        }

        this._canvasDraw();
    }

    async setLookTowards(x, y) {
        await this.setupComplete;

        this.lookTarget = {x: x*1, y: y*-1};
        clearTimeout(this._lookBackTimeout);

        this._lookBackTimeout = setTimeout(() => {
            this.lookTarget = {x: 0, y: 0};
        }, 1000)
    }

    async canvasSetup () {
        this._flatCanvas = document.createElement('canvas');
        this._canvasTexture = new THREE.CanvasTexture(this._flatCanvas);

        this._flatCanvas.setAttribute('width', this._screenWidth);
        this._flatCanvas.setAttribute('height', this._screenHeight);
        this._ctx = this._flatCanvas.getContext('2d');

        this._setGradient();

        this._imgs['default'] = this._imgLogo.decode().then(() => this._imgLogo);
        this._imgs['loading'] = this._imgLoading.decode().then(() => this._imgLoading);
    }

    async _canvasDraw () {
        const width = this._screenWidth,
            height = this._screenHeight,
            ctx = this._ctx;
        
        await this._imgScreen.decode();
        await this._imgMask.decode();
        let img = await this._imgs[this._image];

        ctx.globalCompositeOperation = 'source-over';
        ctx.fillStyle = '#222';
        ctx.fillRect(0, 0, width, height );
        ctx.drawImage(img, 0, 0, width, height );
        
        // Multiply layers
        ctx.globalCompositeOperation = 'multiply';
        ctx.fillStyle = this._grd;
        ctx.fillRect(0, 0, width, height );
        ctx.drawImage(this._imgScreen, 0, 0, width, height );
        
        // Masking
        ctx.globalCompositeOperation = 'destination-in';
        ctx.drawImage(this._imgMask, 0, 0, width, height );

        if (this._canvasTexture != null )
        {
            this._canvasTexture.needsUpdate = true;
        }

    }

    _setGradient() {
        let screenColour = tinycolor(this.colour);

        // Create gradient
        var grd = this._ctx.createLinearGradient( 0, 0, 0, this._flatCanvas.height );
        screenColour.setAlpha(1);
        grd.addColorStop( 0.9, screenColour.toRgbString() );

        screenColour.setAlpha(0);
        grd.addColorStop( 0.4, screenColour.toRgbString() );

        this._grd = grd;
    }

    async _setImage(imageLocation) {
        if(Object.keys(this._imgs).indexOf(imageLocation) == -1)
        {
            this._image = 'loading';
            this._canvasDraw();
            
            let newImage = new Image();
            newImage.src = imageLocation;

            newImage.decode();
            this._imgs[imageLocation] = await newImage.decode().then(() => newImage );
        }

        this._image = imageLocation;
        this._canvasDraw();
    }

    async threeInit() {
        this.scene = new THREE.Scene();

        this.camera = new THREE.PerspectiveCamera(55, this._screenWidth/this._screenHeight, 100, 1000);
        this.camera.position.z = 600;
        this.scene.add(this.camera);

        this.scene.add(new THREE.AmbientLight(0xffffff, 1));
        this.pointLight = new THREE.PointLight( 0xffffff, 0.5, 10000 );
        this.pointLight.position.set( 100, 500, 400 );
        this.scene.add(this.pointLight);
        
        let scale = 0.65;
        this.geometry.scale(this._screenWidth * scale, this._screenHeight * scale, 70 * scale);

        let material = new THREE.MeshPhongMaterial({
            map: this._canvasTexture,
            transparent: true,
            normalMap: this.normalMap,
            reflectivity: 0.2,
            envMap: this.envMap,
        });

        this.mesh = new THREE.Mesh(this.geometry, material);
        this.scene.add(this.mesh);

        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        this.renderer.setSize(600, 450);

        this._canvas = this.renderer.domElement;
    }

    async startAnimation() {
        await this.setupComplete;

        this.currentLook = {x: 0, y: -400};
        this.lookTarget = {x: 0, y: 0};

        this._lookPostion(this.currentLook.x, this.currentLook.y);
        this.renderer.render(this.scene, this.camera);

        setTimeout(() => {
            this.threeAnimate();
        }, 300);
    }

    async threeAnimate(self) {
        self = self || this;

        if (!self._reachedLook()) {
            self._lerpLook(2);
        }

        self.animationId = requestAnimationFrame(() => {
            self.threeAnimate(self);
        });

        self.renderer.render(self.scene, self.camera);
    }

    _lerpLook(factor) {
        factor = factor || 50;

        const xDist = this.lookTarget.x - this.currentLook.x,
            yDist = this.lookTarget.y - this.currentLook.y,
            dist = Math.sqrt( (xDist * xDist) + (yDist * yDist) );        

        if (dist < factor && dist > 1)
        {
            this._lerpLook(factor/2);
            return;
        }

        let travel = (dist - factor) / (factor * 5),
            xTravel = (xDist/dist) * travel,
            yTravel = (yDist/dist) * travel;

        this.currentLook.x += xTravel;
        this.currentLook.y += yTravel;

        this._lookPostion(this.currentLook.x, this.currentLook.y);
    }

    _reachedLook() {
        const xDist = this.lookTarget.x - this.currentLook.x,
            yDist = this.lookTarget.y - this.currentLook.y,
            dist = Math.sqrt( (xDist * xDist) + (yDist * yDist) );

        return dist < 2;
    }

    _lookPostion(x, y) {
        this.mesh.lookAt( new THREE.Vector3( x, y, 500 ) );
    }

}

export default ScreenDrawer;