<template>
    <div class="title-spacer">
        <div class="title">
            <h1 class="visually-hidden">Stuff No One Asked For</h1>
            <div id="render"></div>
        </div>
    </div>
</template>

<script>
import ScreenDrawer from '../assets/js/ScreenDrawer.js';

export default {
    props: [
        'colour',
        'image',
    ],
    methods: {
        mouseMove(event) {
            const clientRect = this.renderParent.getClientRects()[0],
                screenX = clientRect.x + (clientRect.width / 2),
                screenY = clientRect.y + (clientRect.height / 2),
                x = event.clientX - screenX,
                y = event.clientY - screenY;
            
            this.screen.setLookTowards( x, y );
        },
    },
    async mounted() {
        this.screen = new ScreenDrawer();
        this.renderParent = this.$el.querySelector('#render');

        const scopeName = this.$el.getAttributeNames().filter( attr =>  attr.match(/data-/) )[0],
            canvas = await this.screen.getCanvas();

        canvas.setAttribute(scopeName, '');
        this.renderParent.appendChild(canvas);

        this.$nextTick(() => {
            this.screen.setDisplay({colour: this.colour, image: 'default'});
            this.screen.startAnimation();
            addEventListener('mousedown', this.mouseMove);
        })

    },
    updated() {
        this.$nextTick(() => {
            this.screen.setDisplay({colour: this.colour, image: this.image});
        })
    },
    unmounted() {
        this.screen.destructor();
    }
}
</script>


<style lang="scss" scoped>
.title-spacer {
    padding-bottom: 66%;
    margin-bottom: 2rem;
}
.title {
    position: fixed !important;
    width: calc( min( calc(100vw - 20px), 600px ) - 20px );

    #render {
        width: 100% !important;
        height: 0;
        padding-bottom: 66%;
    
        > canvas {
            max-width: 100% !important;
            width: unset !important;
            height: unset !important;
        }
    }
}

</style>