<template>
    <button class="site" @click="toggleCurrent" :style="{ color: show ? colour : 'white', fontWeight: show ? 'bold' : '' }">
        <span class="title">{{name}}</span>
        
        <span class="links" v-show="show">
            <a :href="link">View</a>&nbsp;
            <span v-if="srcLink">
                [<a :href="srcLink" class="src" target="_blank">
                    src
                    <svg width="15" height="15" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M17 13.5v6H5v-12h6m3-3h6v6m0-6-9 9" class="icon_svg-stroke" stroke="#fff" stroke-width="1.5" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                </a>]
            </span>
        </span>
    </button>
</template>

<script>
export default {
    emits: ['setCurrentSite'],
    props: [
        'name',
        'colour',
        'image',
        'link',
        'srcLink',
        'closeFlag'
    ],
    data() {
        return {
            show: false
        };
    },
    watch: {
        closeFlag() {
            if (this.closeFlag)
            {
                this.show = false;
            }
        }
    },
    methods: {
        toggleCurrent() {
            const prevShow = this.show;

            if (!this.show)
            {
                this.$emit('setCurrentSite', {colour: this.colour, image: this.image});
            }
            else
            {
                this.$emit('setCurrentSite', {});
            }

            let self = this;

            this.$nextTick(function() {
                self.show = !prevShow;
            })
        },
    },
}
</script>


<style lang="scss" scoped>

.site {
    width: 300px;
    max-width: 100%;
    color: currentColor;
    background: rgba(0,0,0,0.5);
    border: none;
    border: 4px solid currentColor;

    margin-bottom: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .links {
        display: block;
        margin-top: 0.5rem;

        svg {
            margin-bottom: 10px;
            margin-right: 2px;
        }
    }
}

</style>