<template>
    <div class="container-fluid" :style="{backgroundImage: currentGadient}">
        <div class="content">
            <main>
                <screen-title :colour="bgColour" :image="image"/>
                <project-list
                    title="Work"
                    :siteList="[
                        {
                            name: 'CPA 2019 Financial Report',
                            image: '/cpa.jpg',
                            link: 'https://content.cpaaustralia.com.au/annualreport-2019',
                            srcLink: '',
                            colour: '#ffd400',
                        },
                        {
                            name: 'Podcast Services Australia',
                            image: '/psa.jpg',
                            link: 'https://podcastservices.com.au/',
                            srcLink: '',
                            colour: '#9c43b2',
                        },
                        {
                            name: 'Steps Group',
                            image: '/steps-group.jpg',
                            link: 'https://steps-group.com.au/',
                            srcLink: '',
                            colour: '#72cb31',
                        },
                        {
                            name: 'David Jones - Art of Series',
                            image: '/dj-aos.jpg',
                            link: 'https://www.davidjones.com/art-of-living',
                            srcLink: '',
                            colour: '#f9e9d0',
                        },
                    ]"
                    :closeFlag="closeFlag"
                    @set-current-site="setCurrentSite"
                />
                <project-list
                    title="Projects"
                    :siteList="[
                        {
                            name: 'One Page Blog',
                            image: '/monochrome.jpg',
                            link: 'https://demo.snoaf.com/opa',
                            srcLink: 'https://gitlab.com/snoaf-web-modules/one-page-app',
                            colour: '#44eabe',
                        },
                        {
                            name: 'Msg Stories',
                            image: '/messages.png',
                            link: 'https://demo.snoaf.com/msg-stories',
                            srcLink: 'https://gitlab.com/snoaf-web-modules/msg-stories',
                            colour: '#17a2b8',
                        },
                        {
                            name: 'Comic Cards',
                            image: '/comiccards.jpg',
                            link: 'https://demo.snoaf.com/comic-cards',
                            srcLink: 'https://gitlab.com/snoaf-web-modules/comic-cards',
                            colour: '#761529',
                        },
                    ]"
                    :closeFlag="closeFlag"
                    @set-current-site="setCurrentSite"
                />
            </main>
            <footer>
                Made by <a href="mailto:tyson@snoaf.com">Tyson</a> [<a href="https://gitlab.com/snoaf-pages/snoaf-home-page">src</a>]
            </footer>
        </div>
    </div>
</template>

<script>
import tinycolor from 'tinycolor2';

import ScreenTitle from './components/ScreenTitle.vue';
import ProjectList from './components/ProjectList.vue';


export default {
    name: 'App',
    components: {
        ScreenTitle,
        ProjectList,
    },
    data() {
        return {
            currentGadient: '',
            nextBgColour: 'gray',
            prevBgColour: 'gray',
            bgColour: 'gray',
            step: 0,
            stepsTotal: 10,
            image: '',
            closeFlag: false,
        };
    },
    methods: {
        setCurrentSite(currentSiteObj) {
            this.closeAll();

            const imgSet = currentSiteObj && currentSiteObj.hasOwnProperty('image'),
                colourSet = currentSiteObj && currentSiteObj.hasOwnProperty('colour');

            this.image = imgSet ? currentSiteObj.image : 'default';
            this.setColour( colourSet ? currentSiteObj.colour : 'white' );
        },
        closeAll() {
            let self = this;
            self.closeFlag = true;

            this.$nextTick(function() {
                self.closeFlag = false;
            })
        },
        setColour(colour) {
            // Clamp colour Lightness to a max of 60%
            colour = tinycolor(colour).toHsl();
            colour.l = (colour.l > 0.6) ? 0.6 : colour.l;
            this.nextBgColour = colour;

            this.step = 0;
            this.prevBgColour = this.bgColour;

            this.updateColours();
        },
        async updateColours() {
            if ( (this.stepsTotal - this.step) > 0 )
            {
                this.bgColour = tinycolor.mix(this.prevBgColour, this.nextBgColour, 100 * (this.step/this.stepsTotal) ).toString('rgb');
                let bgColourTransparent = tinycolor(this.bgColour).setAlpha(0).toString('rgb');

                this.currentGadient = `linear-gradient(180deg, transparent 0%, ${ bgColourTransparent } 50%, ${this.bgColour} 100%)`;

                this.step++;

                requestAnimationFrame(this.updateColours);
            }
            else
            {
                this.bgColour = tinycolor(this.nextBgColour).toString('rgb');
                let bgColourTransparent = tinycolor(this.bgColour).setAlpha(0).toString('rgb');

                this.currentGadient = `linear-gradient(180deg, transparent 0%, ${ bgColourTransparent } 50%, ${this.bgColour} 100%)`;
            }
        },
    },
    mounted() {
        this.setColour('white');
    },
}
</script>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap/index";

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;

    .container-fluid {
        background: rgb(255,255,255);
        background-attachment: fixed;
        background-size: 100% 100%;
    }

    .content {
        max-width: 600px;
        min-height: 100vh;
        margin: auto ;
        padding: 50px 10px 0;
        color: white;
        background: black;
        text-shadow: 0 0 0.5rem black;

        main {
            > * {
                position: relative;
                z-index: 1;
            }
        }

        footer {
            width: 80%;
            max-width: 400px;
            margin: 3rem auto 0;
            padding: 1rem;
            font-weight: 600;
            text-align: center;
            border-top: 3px solid currentColor;
        }

        .p5Canvas {
            display: none;
        }
    }
}

</style>
